import { BaseSettings } from "src/settings/types";
import { AssertSubtype } from "src/typeUtils";

export const settings = {
  environment: "test",
  sentry: {
    dsn: "",
    tracesSampleRate: 0.0,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,
    tracePropagationTargets: [],
    isEnabled: false,
  },
} as const;

export type TestSettings = typeof settings;

// Check that the settings are a subtype of the base settings at compile time
type _typeAssert = AssertSubtype<BaseSettings, TestSettings>;
