import AboutUs from "./AboutUs";
import ContactPage from "./ContactPage";
import Mission from "./Mission";
import Nav from "./Nav";
import React from "react";
import Services from "./Services";
import Vision from "./Vision";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App: React.FC = () => {
  return (
    <>
      <Nav />
      <main>
        <h1 className="large-font">Welcome to BetterCode</h1>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Mission />
                  <Vision />
                </>
              }
            />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </Router>
      </main>
    </>
  );
};

export default App;
