import React from "react";
import "./contact-page.css";
import { Label, Button, TextArea, EmailInput } from "./Components";

function ContactForm(): React.JSX.Element {
  const defaultMessage = "Hi, I would like to be contacted by BetterCode.";

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const rawMessage = data.get("message");
    const message = rawMessage ? rawMessage : defaultMessage;
    alert(`Email: ${email}\nMessage: ${message}`);
  };

  return (
    <>
      <h1>Contact</h1>
      <form onSubmit={onSubmit} className="contact-form">
        <Label htmlFor="email">Email:</Label>
        <EmailInput name="email" required={true} />
        <Label htmlFor="message">Message:</Label>
        <TextArea name="message" placeholder={defaultMessage} />
        <Button type="submit">Submit</Button>
      </form>
    </>
  );
}

export default ContactForm;
