import React from "react";
import NavElement from "./NavElement";
import "./Nav.css";
import { Button } from "./Components";

const Nav: React.FC = () => {
  return (
    <>
      <nav className="nav-bar">
        <div className="logo">
          <a href="/">BetterCode</a>
        </div>
        <ul className="nav-items">
          <NavElement text="About Us" inputRef="/about" />
          <NavElement text="Blog" inputRef="/blog" />
          <NavElement text="Services" inputRef="/services" />
        </ul>
        <a href="/contact">
          <Button className="nav-button">Contact Us</Button>
        </a>
      </nav>
    </>
  );
};

export default Nav;
