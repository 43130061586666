import React from "react";

export interface ButtonProps {
  type?: "button" | "reset" | "submit" | undefined;
  className?: string;
  children: string;
}

export function Button(props: ButtonProps): React.JSX.Element {
  return (
    <button className={props.className} type={props.type}>
      {props.children}
    </button>
  );
}
