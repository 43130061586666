import React from "react";

const Vision: React.FC = () => {
  return (
    <div>
      <h1> Vision </h1>
      <p>
        Our vision is to become a global reference in software development,
        recognized for our commitment to quality and education.
      </p>
      <p>
        We aspire to improve the quality of life for developers and close the
        knowledge gap in the industry, being an influential and credible voice
        for developers, managers, and project owners.
      </p>
      <p>
        We aim to be an educational resource center that drives the industry
        towards excellence and rigor in all its projects, transforming the
        industry so that companies deliver greater value to their customers and
        continuously improve the experience of their developers and end-users.
      </p>
    </div>
  );
};

export default Vision;
