import * as Sentry from "@sentry/react";
import { settings } from "./settings";

Sentry.init({
  dsn: settings.sentry.dsn,
  enabled: settings.sentry.isEnabled,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: settings.sentry.tracesSampleRate,
  tracePropagationTargets: settings.sentry.tracePropagationTargets.slice(),
  replaysSessionSampleRate: settings.sentry.replaysSessionSampleRate,
  replaysOnErrorSampleRate: settings.sentry.replaysOnErrorSampleRate,
});
