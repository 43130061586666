import { AssertSubtype } from "src/typeUtils";
import { BaseSettings } from "src/settings/types";

export const settings = {
  environment: "development",
  sentry: {
    dsn: "",
    tracesSampleRate: 0.0,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,
    tracePropagationTargets: [],
    isEnabled: false,
  },
} as const;

export type DevSettings = typeof settings;

// Check that the settings are a subtype of the base settings at compile time
type _typeAssert = AssertSubtype<BaseSettings, DevSettings>;
