import React from "react";

interface NavElementProps {
  text: string;
  inputRef: string;
}

const NavElement: React.FC<NavElementProps> = (props) => {
  const liStyle = {
    marginRight: "3em",
  };

  const aStyle = {
    color: "#FFF",
    textDecoration: "none",
  };

  return (
    <>
      <li style={liStyle}>
        <a style={aStyle} href={props.inputRef}>
          {props.text}
        </a>
      </li>
    </>
  );
};

export default NavElement;
