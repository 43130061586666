import React from "react";

export interface LabelProps {
  htmlFor: string;
  children: string;
}

export function Label(props: LabelProps): React.JSX.Element {
  return <label htmlFor={props.htmlFor}>{props.children}</label>;
}
