import React from "react";

const Mission: React.FC = () => {
  return (
    <div>
      <h1> Mission </h1>
      <p>
        At BetterCode, our mission is to elevate the standards of quality in
        software development through education, consulting, and application
        maintenance.
      </p>
      <p>
        We are dedicated to sharing knowledge and educating companies to improve
        their processes, fostering a culture of excellence and professionalism
        in the software industry.
      </p>
    </div>
  );
};

export default Mission;
