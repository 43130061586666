import React from "react";
import AboutOne from "./AboutOne";

const AboutUs: React.FC = () => {
  const nachoDescription = `
    I'm a Software Engineer and Architect, passionate about innovation, 
    quality, and enhancing user experience.
    `;
  const nachoImageProps = {
    path: "./nacho.jpeg",
    altText: "A picture of Ignacio Tolosa",
    size: "200px",
  };
  const nicoDescription = `
    I'm a Product Manager and a Software Enthusiast, passionate about 
    innovation and quality, delivering exepcional value to the users. 
    `;
  const nicoImageProps = {
    path: "./nico.jpg",
    altText: "A picture of Nicolás Orellana",
    size: "200px",
  };
  const yoyiDescription =
    "\
    I'm a Software Gardener and Mentor, using Rust and Ruby to build high\
    quality software.";
  const yoyiImageProps = {
    path: "./yoyi.png",
    altText: "A picture of Rodrigo Vilina",
    size: "200px",
  };

  return (
    <>
      <AboutOne
        name="Ignacio Tolosa"
        description={nachoDescription}
        imageProps={nachoImageProps}
      />
      <AboutOne
        name="Nicolás Orellana"
        description={nicoDescription}
        imageProps={nicoImageProps}
      />
      <AboutOne
        name="Rodrigo Vilina"
        description={yoyiDescription}
        imageProps={yoyiImageProps}
      />
    </>
  );
};

export default AboutUs;
