import React from "react";

export interface InputProps {
  type: string;
  name: string;
  required: boolean;
}

export function Input(props: InputProps): React.JSX.Element {
  return (
    <>
      <input type={props.type} name={props.name} required={props.required} />
    </>
  );
}
