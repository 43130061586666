import React from "react";

export interface Props {
  path: string;
  altText: string;
  size: string;
}

const CircularPicture: React.FC<Props> = (props) => {
  const style = {
    borderRadius: 100,
    width: props.size,
    height: props.size,
  };

  return (
    <>
      <img style={style} src={props.path} alt={props.altText} />
    </>
  );
};

export default CircularPicture;
