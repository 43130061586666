import { AssertSubtype } from "src/typeUtils";
import { BaseSettings } from "src/settings/types";

export const settings = {
  environment: "production",
  sentry: {
    dsn: "https://e268447b15f101449cc9e4757ba58b5b@o4507591325319168.ingest.us.sentry.io/4507591332331520",
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/bettercode\.cl/],
    isEnabled: true,
  },
} as const;

export type ProdSettings = typeof settings;

// Check that the settings are a subtype of the base settings at compile time
type _typeAssert = AssertSubtype<BaseSettings, ProdSettings>;
