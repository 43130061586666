import React from "react";
import ServiceCard from "./ServiceCard";

function Services(): React.JSX.Element {
  return (
    <div>
      <h2>Services</h2>
      <ServiceCard />
    </div>
  );
}

export default Services;
