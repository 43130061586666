import React from "react";
import { Input } from "../Atoms";

export interface EmailInputProps {
  name: string;
  required: boolean;
}

// I don't know if this is a molecule or an Atom.
// -> I will assume it is a molecule.
export function EmailInput(props: EmailInputProps): React.JSX.Element {
  return <Input type="email" {...props} />;
}
