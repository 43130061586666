import React from "react";

export interface TextAreaProps {
  name?: string;
  placeholder?: string;
  required?: boolean;
}

export function TextArea(props: TextAreaProps): React.JSX.Element {
  return (
    <textarea
      name={props.name}
      placeholder={props.placeholder}
      required={props.required}
    />
  );
}
