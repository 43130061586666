import { settings as dev, DevSettings } from "./environments/development";
import { settings as prod, ProdSettings } from "./environments/production";
import { settings as test, TestSettings } from "./environments/test";

type Settings = DevSettings | ProdSettings | TestSettings;

export function buildSettings(env: "production"): ProdSettings;
export function buildSettings(env: "test"): TestSettings;
export function buildSettings(env: string | undefined): DevSettings;
export function buildSettings(env?: string): Settings {
  switch (env) {
    case "production":
      return prod;
    case "test":
      return test;
    default:
      return dev;
  }
}

export const settings = buildSettings(process.env.NODE_ENV);
