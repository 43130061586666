import React, { CSSProperties } from "react";
import CircularPicture, {
  Props as CircularPictureProps,
} from "./CircularPicture";

interface Props {
  name: string;
  description: string;
  imageProps: CircularPictureProps;
}

const AboutOne: React.FC<Props> = (props) => {
  const style: CSSProperties = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
  const divStyle = {
    marginLeft: "30px",
    width: "500px",
  };
  const hStyle = {
    marginBottom: "10px",
  };

  return (
    <div style={style}>
      <CircularPicture {...props.imageProps} />
      <div style={divStyle}>
        <h1 style={hStyle}>{props.name}</h1>
        <p>{props.description}</p>
      </div>
    </div>
  );
};

export default AboutOne;
